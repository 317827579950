<template>
    <div id="supervisor-contact">

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste Des Messages" @refresh="loadData" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Date",
                        field: "createdAt",
                    },
                    {
                        headerName: "Nom",
                        field: "name",
                    },
                    {
                        headerName: "Email",
                        field: "email",
                    },
                    {
                        headerName: "Message",
                        field: "message",
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("contact").catch(() => this.showLoading = false);
            this.agGrid.rows = response.data;
            this.showLoading = false;
        }
    },
}
</script>